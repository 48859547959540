// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //apiUrl: 'http://localhost:22900/api/',
  apiUrl: 'https://api.servispozitif.com/api/',
  
  sirketadi: 'Servis Pozitif',
  sirketlogo: 'https://app.servispozitif.com/Content/images/servis-pozitif-log.png',
  sirketadres:"Ostim Teknopark Turuncu Bina No:42 / Ankara",
  sirkettel:"TEL: +90 535 306 16 24",
  sirketemail:"destek@servispozitif.com",
  website:"https://www.servispozitif.com/",
  instagram:"https://www.instagram.com/servispozitif/",
  facebook:"https://www.facebook.com/servispozitif/",
  apikey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJLRVkiOnsia3VydW1hcGlrZXkiOiI4OTk2QTVDMi0yQTdBLTRGREEtOEYxNS1EMjI0NUYyMjRCOUEiLCJzZXJ2aXNuYW1lIjoiQ0lIQVpTT1JHVSJ9fQ.6iNa_4lwByx5vOqQoUMXnYbJm6M9PduNaJ5g9k2j9zs',

};
